





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  name: 'InsSelectSearch',
  components: {
  }
})
export default class InsSelectSearch extends Vue {
    @Prop({ default: () => {} }) private data!: object; // 选择数据
    @Prop({ default: 0 }) private type!: number; // 类型（产品属性/产品目录）

    value: string = '';

    changeSelect () {
      if (this.type === 0) {
        this.$emit('changeSelect', { Id: (this.data as any).Id, Vals: this.value });
      } else {
        this.$emit('changeSelect', this.value);
      }
    }
}
